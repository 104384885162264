<template>
    <div id="mnEditTable">
        <div class="search-intent">
            <span>意图筛选：</span>
            <el-select v-model="intentValue" @change="selectIntent" filterable placeholder="请选择意图">
                <el-option v-for="item in intentOptions" :key="item.attr" :label="item.attrName" :value="item.attr">
                </el-option>
            </el-select>
        </div>
        <el-table :data="tableData" height="calc(100vh - 50px)" style="width: 100%"
            :header-cell-style="{ backgroundColor: '#F6F8FD', height: '50px', color: '#000000', fontWeight: 400, borderRadius: ' 5px 5px 0px 0px' }"
            :row-style="{ color: '#000000', lineHeight: '68px', height: '68px' }" v-loading="loading"
            element-loading-text="数据加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.6)">
            <!-- <el-table-column prop="name" label="意图" width="200">
            </el-table-column> -->
            <el-table-column prop="attr1Name" label="属性_1" width="150">
            </el-table-column>
            <el-table-column prop="attr2" label="属性_2" width="150">
            </el-table-column>
            <el-table-column prop="attr3" label="属性_3" width="150">
            </el-table-column>
            <el-table-column prop="attr4" label="属性_4" width="150">
            </el-table-column>
            <el-table-column prop="name" label="回复">
                <template slot-scope="scope">
                    <div class="replay-content" v-html="scope.row.reply">
                    </div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="80">
                <template slot-scope="scope">
                    <div class="operation-button">
                        <span class="operation-button-span" @click="editReply(scope.row)">编辑回复</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <pagination :pageSize="taskPageSize" :currentPage="taskPage" :total="taskTotal" @handleSizeChange="handleSizeChange"
            @currentChange="handleCurrentChange">
        </pagination>
        <popup v-if="editRichText" @closeEvent="editRichText = false">
            <div slot="popup-name" class="popup-name">编辑回复</div>
            <div slot="popup-con">
                <!-- <ckeditor :editor="ckeditor.editor" v-model="content" :config="editorConfigTool"
                    @focus="(zh, editor) => (currentEditorTitle = editor)"
                    @ready="(editor) => (currentEditorTitle = editor)"></ckeditor> -->
                <ckedit-cell ref="ckeditcellref" :unitContentHtml="content" :index="1"></ckedit-cell>
            </div>
            <div slot="dialog-footer" class="member-footer">
                <el-button @click="editRichText = false" size="small" plain>取 消</el-button>
                <el-button type="primary" @click="saveReply" size="small">保 存</el-button>
            </div>
        </popup>
    </div>
</template>

<script>
import pagination from "../fissionMarketing/components/pagination";
import CkeditCell from "./CkeditCell.vue";
import Popup from "./../../components/popup.vue";
import { MyCustomUploadAdapterPlugin } from "./../ChatFlow/component/answer/js/ckeditor";
import CKEDITOR from "ckeditor";

export default {
    name: "mnEditTable",
    data() {
        return {
            tableData: [],
            taskPage: 1,
            taskPageSize: 20,
            taskTotal: 0,
            loading: true,
            editRichText: false,

            content: "",
            ckeditor: {
                editor: CKEDITOR.ClassicEditor,
            },
            editorConfigTool: {
                extraPlugins: [MyCustomUploadAdapterPlugin],
                toolbar: [
                    // "heading",
                    // "|",
                    // "bold",
                    // "italic",
                    // "link",
                    // "bulletedList",
                    // "numberedList",
                    // "|",
                    // "imageUpload",
                    // "insertTable",
                    // "mediaEmbed",
                    "Undo",
                    "Redo",
                ],
                askPluginListener: [
                    {
                        event: "PLACEHOLDER",
                        process: (data, callback) => {
                            console.debug("editor call back", data, callback);
                        },
                    },
                ], // 注册监听
            },
            currentEditor: null,
            currentEditorTitle: null,
            activeItem: { name: '' },
            intentValue: '',
            intentOptions: [],
        };
    },
    components: { pagination, Popup, CkeditCell },
    filters: {},
    methods: {
        //获取群发任务
        getReply() {
            let data = Object.assign({}, this.taskPage, {
                page: this.taskPage,
            });
            let url =
                "/api/config/common/page?page=" +
                data.page +
                "&size=" +
                this.taskPageSize;
            if (this.intentValue != '') {
                url = url + '&attr1=' + this.intentValue;
            }
            this.FetchGet(url).then((res) => {
                let list = res.data.list;
                list.forEach(item => {
                    if (!item.attr1Name) {
                        item.attr1Name = item.attr1;
                    }
                })
                this.tableData = res.data.list;
                this.taskTotal = res.data.total;
                this.loading = false;
            });
        },

        getIntentList() {
            let url = "/api/config/common/attr1";
            this.FetchGet(url).then((res) => {
                this.intentOptions = res.data;
            });
        },
        selectIntent(value){
            console.log(value);
            this.taskPage = 1;
            this.getReply();
        },

        //编辑
        editReply(item) {
            this.activeItem = item;
            this.content = item.reply;
            this.editRichText = true;
        },
        saveReply() {
            let content = this.$refs.ckeditcellref.unitContentHtml;
            let url =
                "/api/config/common/" + this.activeItem.id;
            this.FetchPut3(url, { reply: content }).then((res) => {
                if (res.code == 0) {
                    this.tableData.forEach(item => {
                        if (item.id === this.activeItem.id) {
                            item.reply = content;
                        }
                    })
                    this.editRichText = false;
                    this.$message.success('保存成功');
                } else {
                    this.$message.error('保存失败');
                }
            });
        },
        handleSizeChange(value) {
            this.taskPageSize = value;
            this.getReply();
        },
        handleCurrentChange(value) {
            this.taskPage = value;
            this.getReply();
        },
    },
    mounted() {
        this.getIntentList();
        this.getReply();
    },
};
</script>

<style scoped lang="less">
#mnEditTable {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 52px);

    .search-intent {
        margin-left: 10px;
        height: 60px;
        display: flex;
        align-items: center;

        .el-select {
            width: 500px;
        }
    }

    .replay-content {
        max-height: 200px;
        overflow-y: auto;
    }

    /*width: calc(100vw - 90px);*/

    .create-date {
        display: flex;
        align-items: center;

        .guoran-a-12-09 {
            padding-right: 10px;
            color: #D2D8E3;
            font-size: 14px;
        }
    }

    .operation-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #366AFF;
        width: 100%;

        .operation-button-span {
            cursor: pointer;
        }

        .el-switch.is-disabled {
            opacity: 1 !important;
        }

        .el-switch.is-disabled .el-switch__core,
        .el-switch.is-disabled .el-switch__label {
            cursor: pointer !important;
        }
    }

    /deep/.el-pagination {
        padding: 2px 5px !important;
    }
}
</style>
